<template>
  <div class="checkbox-list">
    <div v-for="item in items" class="radio-element" @click="handleElementClick(item)" :class="{'checked': isChecked(item)}">
      <div class="radio">
        <div class="radio-dot">
          <div class="radio-dot-inner"></div>
        </div>
        <div class="radio-content">
          <div>{{ item.name }}</div>
          <div v-if="item.description" class="radio-description">{{ item.description }}</div>
        </div>
      </div>
      <div class="price">
        {{ getPrice(item.price) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxList",
  props: {
    items: {
      type: Array,
    },
    value: {
      type: [String, Array],
    },
  },
  methods: {
    isChecked(item) {
      if (!this.value) {
        return false;
      }
      if (typeof this.value === "string") {
        return this.value === item.id
      } else {
        return this.value.includes(item.id);
      }
    },
    getPrice(price) {
      const ch = price > 0 ? '+ ' : (price < 0 ? '- ' : '');
      return `${ch}${Math.abs(price).toFixed(2).replace('.', ',')} zł`;
    },
    handleElementClick(item) {
      if (!this.value || typeof this.value === "string") {
        this.$emit('input', item.id);
      } else {
        const tmp = [...this.value];
        if (this.value.includes(item.id)) {
          const ind = tmp.indexOf(item.id);
          if (ind > -1) {
            tmp.splice(ind, 1);
          }
        } else {
          tmp.push(item.id);
        }
        this.$emit('input', tmp);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.checkbox-list {
  display: flex;
  flex-direction: column;

  .radio-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 3px 0;
    background-color: var(--default-background);
    transition: background-color .2s ease;
    .radio {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .radio-content {
      display: flex;
      flex-direction: column;
      text-align: left;
      .radio-description {
        font-size: 13px;
        color: var(--text-secondary);
      }
    }
    .radio-dot {
      display: inline-flex;
      width: 22px;
      height: 22px;
      border-radius: 3px;
      margin: 4px 6px 4px 2px;
      justify-content: center;
      align-items: center;
      border-width: 2px;
      border-style: solid;
      border-color: #E5E5E5;
      transition: border-color .3s ease;
      .radio-dot-inner {
        width: 0;
        height: 0;
        border-radius: 1px;
        background-color: var(--primary);
        transition: height .3s ease, width .3s ease;
      }
    }
    .price {
      transition: color .2s ease;
    }
    &:hover {
      background-color: var(--input-background-secondary);
    }
    &.checked {
      .radio-dot {
        border-color: var(--primary);
        .radio-dot-inner {
          width: 12px;
          height: 12px;
        }
      }
      .price {
        color: var(--primary);
      }
    }
  }
}
</style>
