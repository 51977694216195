<template>
  <div class="number_field">
    <div class="change_number" :class="{'disabled': leftIsDisabled}" @click="decrement">-</div>
    <div>
      <input type="text" :value="value" @input="handleInput">
    </div>
    <div class="change_number" :class="{'disabled': rightIsDisabled}" @click="increment">+</div>
  </div>
</template>

<script>
export default {
  name: "NumberField",
  props: {
    value: [String, Number],
    minValue: Number,
    maxValue: Number,
  },
  computed: {
    leftIsDisabled() {
      return parseInt(this.value) <= this.minValue;
    },
    rightIsDisabled() {
      return parseInt(this.value) >= this.maxValue;
    },
  },
  methods: {
    decrement() {
      if (this.leftIsDisabled) {
        return;
      }
      let val = typeof this.value === 'string' ? parseInt(this.value) : this.value;
      if (isNaN(val)) {
        val = 1;
      }
      this.$emit('input', val - 1);
    },
    increment() {
      if (this.rightIsDisabled) {
        return;
      }
      let val = typeof this.value === 'string' ? parseInt(this.value) : this.value;
      if (isNaN(val)) {
        val = 1;
      }
      this.$emit('input', val + 1);
    },
    handleInput(e) {
      const val = e.target.value;
      if (/^[0-9]+$/g.test(val)) {
        if (parseInt(val) < this.minValue) {
          this.$emit('input', this.minValue);
          e.target.value = this.minValue;
        } else if (parseInt(val) > this.maxValue) {
          this.$emit('input', this.maxValue);
          e.target.value = this.maxValue;
        } else {
          this.$emit('input', parseInt(val));
          e.target.value = val;
        }
      } else if (!val) {
        this.$emit('input', this.minValue);
        e.target.value = this.minValue;
      } else {
        e.target.value = this.value;
        this.$emit('input', this.value);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.number_field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .change_number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--border);
    background-color: var(--primary);
    color: var(--white);
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    &.disabled {
      background-color: var(--secondary);
    }
  }
  input {
    width: 40px;
    height: 40px;
    text-align: center;
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: var(--border);
    box-sizing: border-box;
  }
}
</style>
