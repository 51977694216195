<template>
  <div class="page page--additions animated_content">
    <SeqEntrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated" :title="item.name" with-menu/>
      <div class="col-1 no_animation">
        <div class="col info_container">
          <SeqEntrance animation="animate__fadeInUp" tag="div">
            <div class="image_container animate__animated">
              <img v-if="item.image" :src="$asset(item.image.path)" :alt="item.name">
            </div>
            <InfoBox space class="animate__animated">
              <div class="info_line">
                <span class="product_title">{{ item.name }}<br>
                <Tag v-for="tag in itemMenu.tags" :color="tag.color">{{ tag.name }}</Tag>
                </span>
                <span class="product_price" v-if="item.price">
                  {{ itemUnitPrice }}&nbsp;zł
                  <span v-if="boxUnitPrice" class="info_line box_price" style="justify-content: flex-end;font-size: .9rem;">+ opakowanie {{ boxUnitPrice }}&nbsp;zł</span>
                </span>
              </div>
              <p class="info_line" v-if="item.description">{{ item.description }}</p>
              <p class="info_line" v-if="itemMenu.variants_count === 1 && itemMenu.no_empty_variants_count === 0 && item.variants && item.variants[0]">
                <i>{{ item.variants[0].name }}</i>
                <span>{{ item.variants[0].price < 0 ? '- ' : (item.variants[0].price > 0 ? '+ ' : '') }}{{ Math.abs(item.variants[0].price).toFixed(2).replace('.', ',') }} zł</span>
              </p>
            </InfoBox>
            <div class="animate__animated">
              <h4 v-if="itemMenu.variants_count > 1 || itemMenu.no_empty_variants_count >= 1" >Warianty</h4>
              <InfoBox space v-if="itemMenu.variants_count > 1 || itemMenu.no_empty_variants_count >= 1">
                <RadioList
                    v-model="form.variant_id"
                    :items="item.variants"
                    @input="validateFormTick"
                />
              </InfoBox>
            </div>
          </SeqEntrance>
          <SeqEntrance animation="animate__fadeInUp" tag="div">
            <div v-for="category in categories" :key="category.id" class="animate__animated">
              <h4>{{ category.name }}</h4>
              <InfoBox space>
                <CheckboxList
                    v-if="category.many === 1"
                    v-model="form.additions"
                    :items="category.additions"
                    @input="validateFormTick"
                />
                <RadioList
                    v-else
                    v-model="form.additions"
                    :items="category.additions"
                    :unselectable="category.must === 0"
                    @input="validateFormTick"
                />

              </InfoBox>
            </div>
          </SeqEntrance>
          <SeqEntrance animation="animate__fadeInUp" tag="div" delay="1000">
            <div class="animate__animated"></div>
            <InfoBox space class="clickable animate__animated" @click="openModal('noteModal')">
              <div class="info_line">
                <span class="info_title">Notatka</span>
                <i class="alternative-text" v-if="!form.note">Kliknij, aby dodać notatkę</i>
                <i v-else>{{ form.note }}</i>
              </div>
            </InfoBox>
          </SeqEntrance>
        </div>
      </div>
      <div class="animate__animated">
        <p class="form-amount-title">Liczba sztuk:</p>
        <div style="margin-bottom: 30px">
          <NumberField
            v-model="form.quantity"
            :min-value="1"
            :max-value="50"
          />
        </div>
      </div>
      <div class="delete_button animate__animated" v-if="isEdit">
        <div class="hr"></div>
        <Btn @click="deleteFromCart" class="add-to-cart">
          Usuń z koszyka
        </Btn>
      </div>

      <div class="floating-add-to-cart animate__animated">
        <Btn @click="addToCart" :disabled="!formIsValid" class="add-to-cart">
          <div>
            {{ itemQuantity }}
          </div>
          <div>
            <span v-if="isEdit">&nbsp;Zapisz&nbsp;</span>
            <span v-else>Dodaj do koszyka</span>
          </div>
          <div>
            {{ itemPrice }}
          </div>
        </Btn>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </SeqEntrance>
    <Drawer/>
    <SearchAddress/>
    <Cart/>
    <Modal title="Notatka" ref="noteModal" backdrop-hide>
      <TextareaField
          v-model="form"
          name="note"
          placeholder='Np. "Dla Tomasza" lub "Bez cebuli"'
          maxlength="160"
      />
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Btn from "@/components/Btn";
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import Drawer from "@/parts/Drawer";
import SearchAddress from "@/parts/SearchAddress";
import RestaurantInfo from "@/parts/RestaurantInfo";
import InfoBox from "@/parts/InfoBox";
import MenuHeader from "@/parts/MenuHeader";
import MenuItem from "@/parts/MenuItem";
import Cart from "@/parts/Cart";
import RadioList from "@/parts/RadioList";
import CheckboxList from "@/parts/CheckboxList";
import NumberField from "@/components/NumberField";
import Modal from "@/components/Modal";
import TextareaField from "@/components/TextareaField";
import SeqEntrance from "@/components/SeqEntrance";
import Tag from "@/components/Tag";

export default {
  name: "Additions",
  components: {
    Tag,
    SeqEntrance,
    TextareaField,
    Modal,
    NumberField,
    Btn,
    CheckboxList,
    RadioList,
    MenuItem,
    MenuHeader,
    InfoBox,
    RestaurantInfo,
    PlainFooter,
    Drawer,
    SearchAddress,
    StickyHeader,
    Cart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    restaurant_id: {
      type: String,
      required: true,
    },
    cart_product_id: {
      type: String,
    },
  },
  data() {
    return {
      item: {},
      itemMenu: {},
      categories: [],
      form: {
        variant_id: null,
        additions: [],
        quantity: 1,
        note: '',
      },
      formIsValid: false,
    };
  },
  computed: {
    ...mapGetters([
      'cart',
      'restaurantsMenu',
      'restaurantMenuItems',
      'isLogged',
    ]),
    itemUnitPrice() {
      if (!this.item.box_price || this.item.box_price === 0) {
        return this.item.price.toFixed(2).replace('.', ',');
      }
      return (this.item.price - this.item.box_price).toFixed(2).replace('.', ',');
    },
    boxUnitPrice() {
      if (!this.item.box_price || this.item.box_price === 0) {
        return false;
      }
      return this.item.box_price.toFixed(2).replace('.', ',');
    },
    itemPrice() {
      if (!this.item.price) {
        return '';
      }
      let price = this.item.price;
      if (this.form.variant_id) {
        this.item.variants.forEach(a => {
          if (a.id === this.form.variant_id) {
            price += a.price;
          }
        });
      }
      this.categories.forEach(a => {
        a.additions.forEach(b => {
          if (this.form.additions.includes(b.id)) {
            price += b.price;
          }
        })
      });
      return `${(price * this.form.quantity).toFixed(2).replace('.', ',')} zł`;
    },
    itemQuantity() {
      if (!this.form.quantity) {
        return '';
      }
      if (this.form.quantity === 1) {
        return '1 pozycja';
      } else if (this.form.quantity > 1 && this.form.quantity < 5) {
        return `${this.form.quantity} pozycje`;
      } else {
        return `${this.form.quantity} pozycji`;
      }
    },
    isEdit() {
      return !!this.cart_product_id;
    },
    cartProduct() {
      if (!this.isEdit || !this.cart.products) {
        return null;
      }
      return this.cart.products.find(a => a.id === this.cart_product_id);
    },
  },
  watch: {
    restaurantsMenu: {
      handler(val) {
        if (val[this.restaurant_id]) {
          val[this.restaurant_id].forEach(a => {
            const tmp2 = a.data.find(b => b.id === this.id);
            if (tmp2) {
              this.itemMenu = {...tmp2};
              this.validateFormTick();
              this.$nextTick(() => {
                this.setFirstVariant();
              });
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
    restaurantMenuItems: {
      handler(val) {
        if (val[this.id]) {
          this.item = {...val[this.id]};
          this.$nextTick(() => {
            this.setFirstVariant();
          });
        }
      },
      deep: true,
      immediate: true,
    },
    'form.variant_id': {
      handler(val) {
        this.setCategories(val);
      },
      immediate: true,
    },
    // cartProduct: {
    //   handler(val) {
    //
    //   },
    //   immediate: true,
    //   deep: true,
    // }
  },
  methods: {
    openModal(modal) {
      if (this.$refs[modal]) {
        this.$refs[modal].show();
      }
    },
    setCategories(variantId) {
      if (!this.item.variants) {
        return;
      }
      const v = this.item.variants.find((i) => i.id === variantId);
      if (v) {
        this.$set(this, 'categories', v.additions_categories.map((i) => {
          i.additions = i.additions.map((ii) => {
            ii.name = ii.partner_addition.name;
            return ii;
          });
          return i;
        }));
        this.$set(this.form, 'additions', []);
      } else {
        this.$set(this, 'categories', []);
        this.$set(this.form, 'additions', []);
      }
    },
    validateFormTick() {
      this.$nextTick(() => {
        this.validateForm();
      });
    },
    validateForm() {
      let ret = true;
      if (this.itemMenu.variants_count > 0 && !this.form.variant_id) {
        ret = false;
      }
      if (this.categories.some(a => {
        return a.must === 1 && !this.form.additions.some(b => {
          return a.additions.findIndex(c => c.id === b) !== -1;
        });
      })) {
        ret = false;
      }
      this.formIsValid = ret;
      return ret;
    },
    addToCart() {
      if (!this.validateForm()) {
        this.$toast.error('Nie można dodać produktu, uzupełnij brakujące pola.');
        return;
      }
      if (!this.isLogged) {
        this.$toast.error('Tylko zalogowani użytkownicy mogą składać zamówienia.'); // TODO: inny modal
        return;
      }
      if (this.cart.partner && this.cart.partner.id && this.cart.partner.id !== this.restaurant_id) {
        this.$toast.error('W koszyku posiadasz już produkty z innej restauracji.');
        return;
      }
      if (!this.isEdit) {
        this.$store.dispatch('cartAddProduct', {form: {...this.form, product_id: this.id}})
            .then(({statusCode, message}) => {
          this.afterResponse(statusCode, message);
        });
      } else {
        this.$store.dispatch('cartEditProduct', {form: {...this.form, product_id: this.id, id: this.cart_product_id}})
            .then(({statusCode, message}) => {
          this.afterResponse(statusCode, message);
        });
      }
    },
    deleteFromCart() {
      this.$store.dispatch('cartDeleteProduct', { form: { id: this.cart_product_id }}).then(() => {
        this.$store.dispatch('reCalcCart').then(() => {
          if (window.history.length > 2) {
            this.$router.back();
          } else {
            this.$router.push({name: 'Restaurant', params: {id: this.restaurant_id}})
          }
          this.$store.commit('setShowCart', true);
        });
      });
    },
    afterResponse(statusCode, message) {
      if (statusCode === 200) {
        if (window.history.length > 2) {
          this.$router.back();
        } else {
          this.$router.push({name: 'Restaurant', params: {id: this.restaurant_id}})
        }
        if (this.isEdit) {
          this.$store.commit('setShowCart', true);
        }
      } else if (statusCode !== 500) {
        if (message === 'partner_inactive') {
          this.$toast.error('Restauracja w tej chwili jest niedostępna.'); // TODO: inny modal
          // setShowPartnerClosed(true);
        } else if (message === 'partner_closed') {
          this.$toast.error('Restauracja w tej chwili jest niedostępna.'); // TODO: inny modal
          // setShowPartnerClosed(true);
        } else if (message === 'different_partner') {
          this.$toast.error('W koszyku posiadasz już produkty z innej restauracji.');
        } else {
          // TODO: handle other errors
        }
      } else {
        this.$toast.error(`<strong>Upsss</strong><br>${message}`);
      }
    },
    setEditValues() {
      if (this.cartProduct) {
        this.$set(this.form, 'quantity', this.cartProduct.quantity);
        this.$set(this.form, 'note', this.cartProduct.note);
        this.$set(this.form, 'variant_id', this.cartProduct.variant_id);
        this.$nextTick(() => {
          this.$set(this.form, 'additions', this.cartProduct.options.additions);
          this.$nextTick(() => {
            this.validateForm();
          });
        });
      } else {
        setTimeout(() => {
          this.setEditValues();
        }, 100);
      }
    },
    setFirstVariant() {
      if (!this.isEdit && this.item.id && this.itemMenu.id) {
        if (this.itemMenu.no_empty_variants_count === 0 && this.itemMenu.variants_count === 1) {
          this.$set(this.form, 'variant_id', this.item.variants[0].id);
          this.validateFormTick();
        } else if (this.itemMenu.no_empty_variants_count === 1 && this.itemMenu.variants_count === 1) {
          this.$set(this.form, 'variant_id', this.item.variants[0].id);
          this.validateFormTick();
        }
      }
    }
  },
  created() {
    this.$store.dispatch('fetchRestaurantMenu', {restaurantId: this.restaurant_id}).then(() => {
      this.restaurantsMenu[this.restaurant_id].forEach(a => {
        const tmp2 = a.data.find(b => b.id === this.id);
        if (tmp2) {
          this.itemMenu = {...tmp2};
          this.$nextTick(() => {
            this.setFirstVariant();
          });
        }
      });
    });
    this.$store.dispatch('fetchRestaurantMenuItem', {itemId: this.id}).then(() => {
      this.item = {...this.restaurantMenuItems[this.id]};
      if (this.isEdit) {
        this.setEditValues();
      }
      this.$nextTick(() => {
        this.setFirstVariant();
      });
    });
  }
}
</script>

<style scoped lang="scss">
.flex-fill {
  flex: 1;
}
.col-1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .col {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px;
    max-width: 600px;
    width: calc(100% - 30px);
    //&:last-of-type {
    //  padding-left: 0;
    //}
    h4 {
      font-weight: 500;
    }
  }
  .menu_container {
    flex: 2;
  }
  .info_container {
    padding-top: 15px;
    .info_title {
      display: flex;
      margin-top: 0;
      margin-bottom: 2px;
      font-weight: 500;
    }
    .info_line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
      margin-bottom: 2px;
      text-align: left;
      p {
        margin: 0;
        a {
          color: var(--text);
          text-decoration: none;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.product_title {
  font-weight: 500;
}
.product_price {
  font-weight: 500;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.box_price {
  font-weight: normal;
  color: var(--text-secondary);
}
.image_container {
  border-radius: var(--border-radius);
  overflow: hidden;
  max-height: 200px;
  margin-bottom: 15px;
  transition: max-height .4s ease;
  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    transition: max-height .4s ease;
  }
  &:hover {
    max-height: 500px;
    img {
      max-height: 500px;
    }
  }
}
.floating-add-to-cart {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .add-to-cart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div:nth-of-type(1) {
      padding-right: 15px;
    }
    div:nth-of-type(2) {
      font-weight: bold;
      font-size: 16px;
    }
    div:nth-of-type(3) {
      padding-left: 15px;
    }
  }
}
.form-amount-title {
  color: var(--primary);
}
.clickable {
  cursor: pointer;
  &:hover, &:focus {
    background-color: var(--input-background-secondary);
  }
}
.alternative-text {
  color: var(--text-secondary);
}
.delete_button {
  margin-bottom: 30px;
}
.hr {
  width: calc(100% - 30px);
  padding-left: 15px;
  padding-right: 15px;
  height: 1px;
  background-color: var(--border);
  border-radius: 3px;
  margin: 5px auto;
  max-width: 570px;
}
</style>
